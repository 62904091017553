
header ul li {
    margin-right: 15px !important;
    padding-bottom: 5px;
}
header ul li.active {
    border-bottom: 3px solid #6bdbef;
}
header img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #6bdbef;
}

header #search_input {
    background-color: #1c2430 !important;
    border: none;
    outline: none;
    padding: 5px 20px 5px 45px;
    border-radius: 15px;
    font-size: 12px;
    color: white;
    width: 300px;
}
header .search i {
    position: absolute;
    left: 15px;
    color: white;
    font-size: 11px;
    top: 50%;
    transform: translateY(-50%);
}

header select {
    background-color: var(--main-color);
}
/* 
    =================================================================== Responsive ====================================================================
*/

@media (max-width: 991px) {
    header #search_input {
        width: 250px;
    }
}
@media (max-width: 768px) {
    header #search_input {
        width: 200px;
    }
    header li a {
        font-size: 11px;
    }
    header {
        flex-wrap: wrap !important;
        align-items: center;
    }
    header select {
        order: 3;
        margin-top: 20px !important;
        width: 100%;
    }
}